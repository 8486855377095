import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileScreenButton } from '@fortawesome/pro-light-svg-icons';
import { Radio } from 'antd';

function TextingConsentCard({ isError = false, handleTextConsentChange, consent }) {
  const onConsentChange = (e) => {
    handleTextConsentChange(e.target.value);
  };

  return (
    <>
      <div className={`text-consent-card ${isError ? 'error' : ''}`}>
        <p className="arc-H150 mb4">
          <FontAwesomeIcon icon={faMobileScreenButton} className="mr6" />
          Stay informed
        </p>
        <p className="arc-support mb8">Would you like to receive updates over text messages?</p>
        <Radio.Group onChange={onConsentChange} value={consent}>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
        <p className="footer-text arc-d-flex arc-flex-direction-column mt12">
          <span>* Standard charges may apply for replies</span>
          <span>** Unsubscribe at any time to all, or specific kinds of updates</span>
        </p>
      </div>
      <If condition={isError}>
        <p className="mb20 mr24 ml24 text-consent-error-msg">Please select Yes or No to continue</p>
      </If>
    </>
  );
}

export default TextingConsentCard;
